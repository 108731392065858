$(document).foundation();

function getAnswer() {
    var answers = new Array(
    	"No",
    	"Nah",
    	"No chance",
    	"Yep... just kidding!",
    	"No shot", 
    	"Still broken", 
    	"Negative", 
    	"Don't think so", 
    	"Absolutely not", 
    	"Never",
    	"Try again soon",
    	"Fat chance",
    	"When pigs fly!",
    	"Probably not",
    	"You said Baton Rouge, right?"
    );
    var randomAnswer = answers[Math.floor(Math.random() * answers.length)];
    $('#answer #answered h2').html(randomAnswer);
}

$(window).load(function() {
	setTimeout(function(){ 
		$('#answer #loading').addClass('hide');
		// getAnswer();
		$('#answer #answered').removeClass('hide');
	}, 2000);
});


$('a.button#check-again').click(function() {
	$('#answer #loading').removeClass('hide');
	$('#answer #answered').addClass('hide');
	setTimeout(function(){ 
		$('#answer #loading').addClass('hide');
		getAnswer();
		$('#answer #answered').removeClass('hide');
	}, 2000);
});